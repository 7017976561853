body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.row {
  break-inside: avoid;
}

h1 {
  color: #333;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 8px;
  padding: 10px;
  color: #fff;
}

h2 {
  color: #444;
  font-size: 28px;
  margin-top: 10px;
  border-radius: 8px;
  padding-bottom: 10px;
}

.chart-container {
  background-color: #fff;
  text-align: center;
  padding: 40px;
}

.chart-container.pie {
  width: 50% !important;
}

.metrics-table {
  margin-top: 20px;
  width: 95%;
  border-collapse: collapse;
  break-inside: avoid;
}

.metrics-table th,
.metrics-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.metrics-table th {
  background-color: #f2f2f2;
}

.button {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.metric-box {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  color: #333;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 0 0 200px;
  max-width: 200px;
  break-inside: avoid;
}

.metric-box h3 {
  margin: 0 0 10px;
  font-size: 1rem;
  color: #666;
}

.metric-box p {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
}

.chart-header {
  float: left;
  width: 100%;
  text-align: left;
}

.chart-header h2 {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

.chart-canvas {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  break-inside: avoid;
}

.metrics-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
}

.metrics-row.section {
  break-before: always;
  padding-top: 40px;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }
  #report {
    width: A4 portrait;
    padding: 20px 40px 20px 40px;
    zoom: 0.75;
  }
  .metrics-table {
    width: 100% !important;
    padding-top: 40px !important;
  }
  .row {
    max-width: 100% !important;
  }
  .chart-container.bar canvas,
  .chart-container.line canvas {
    max-width: 100% !important;
  }
  .chart-container.pie {
    width: auto !important;
  }
  .chart-container {
    padding: 20px;
  }
}
